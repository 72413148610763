import React, { useState } from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import * as styles from "./about-us.module.scss"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import andrew from "../../assets/andrew.png"
import sergey from "../../assets/sergey.png"
import brian from "../../assets/brian.png"
import bill from "../../assets/bill.png"
import gary from "../../assets/gary.png"
import tyson from "../../assets/tyson.png"
import { Heading, Text } from "../components/common/typography"

const AboutUsPage = ({ location }) => {
  const [hideBio, setShowBio] = useState(false)
  const [hideBio2, setShowBio2] = useState(false)
  const [hideBio4, setShowBio4] = useState(false)
  const [hideBio5, setShowBio5] = useState(false)
  const [hideBio6, setShowBio6] = useState(false)
  const [hideBio8, setShowBio8] = useState(false)

  return (
    <Layout>
      <HeaderBanner title="About Us" />
      <div className={styles.pageContainer}>
        <Breadcrumb firstLink="/about-us" firstLinkTitle="About Us" />
        <div className={styles.pageText}>
          <Heading>Technology pioneer of Post-Quantum Cyber Security.</Heading>
          <Text>
            Established in 1992, 01 Communique is always at the forefront of
            technology. Our latest innovation is on cyber security with our
            latest development focused on Quantum-Safe Cryptography. Our
            patent-protected Quantum-Safe Cryptography is designed to operate on
            conventional computer systems as we know them today while at the
            same time is secure enough to safeguard against future attacks from
            the world of Quantum Computers. Examples of vertical applications
            are email/file encryption, digital signatures, blockchain security,
            remote access/VPN, password management, credit card security, cloud
            storage, artificial intelligence, IoT (5G), and website security.
          </Text>
          <h3 style={{ marginTop: "50px" }}>The Team </h3>
          <div className={styles.accordionContainer}>
            <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={andrew} alt="Andrew Cheung" />
                <p>Andrew Cheung</p>
                <p>CEO</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio(!hideBio)}
                  >
                    {hideBio ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    Andrew is the founder, President and CEO of 01 Communique.
                    He built the company from scratch in 1992 and took the
                    company public in 2000. Andrew was a nominee of the Ernst
                    and Young Entrepreneur-Of-The-Year award in 2001. He holds a
                    B.Sc. (Hon) degree in Computer Science from the University
                    of Western Ontario. Besides over 25 years experience in
                    communications, mobile, and cyber security solutions
                    development, he also has 12 years experience in patent
                    prosecution. Andrew is a co-inventor of 7 patents plus
                    several patent-protected applications.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {/* <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={sergey} alt="Sergey Strakhov" />
                <p>Sergey Strakhov</p>
                <p>CTO</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio2(!hideBio2)}
                  >
                    {hideBio2 ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    Sergey joined 01 Communique in 2000. He has over 20 years
                    experience in cyber security and cryptographic related
                    technologies. He also has extensive expertise in firmware
                    development. Sergey holds a M.Sc. in Engineering for
                    Computer Systems from the St. Petersburg State University of
                    Aerospace Instrumentation. He is a co-inventor of 2 patents
                    plus several patent-protected applications.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion> */}
            <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={brian} alt="Brian Stringer" />
                <p>Brian Stringer</p>
                <p>CFO</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio4(!hideBio4)}
                  >
                    {hideBio4 ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    A Chartered Professional Accountant (CPA) with 25+ years
                    experience in managing publicly listed companies, Brian has
                    been the CFO of 01 for over 18 years. Prior to joining 01,
                    Brian worked as the CFO of various other publicly listed
                    companies. In addition to his extensive CFO experience,
                    Brian also has 12 years experience in patent prosecution and
                    patent litigation in the US and Canada.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={tyson} alt="Tyson Macauley" />
                <p>Tyson Macauley</p>
                <p>Board Member</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio8(!hideBio8)}
                  >
                    {hideBio8 ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    Mr. Macaulay, CISA, LEL CIE, brings with him over twenty
                    five years of engineering and management experience in
                    network, cloud and IoT security solutions. Residing in
                    Ottawa he is currently the Chief Security Officer and Vice
                    President of Field Engineering at Rockport Networks Inc.
                    Prior to that, he held the positions of Chief Product
                    Officer at InfoSec Global (ISG), CTO for BAE Systems Applied
                    Intelligence in North America, CTO - Telecommunications at
                    Intel and Chief Security Strategist at Fortinet. These roles
                    all involved product strategy, corporate development
                    (mergers, acquisitions and partnerships), business
                    development and thought leadership.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={bill} alt="William A. Train" />
                <p>William A. Train</p>
                <p>Chairman</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio5(!hideBio5)}
                  >
                    {hideBio5 ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    William joined the board of 01 Communique in 1995. He has
                    over 35 years of extensive experience in the semi-conductor
                    sector in Canada and the United States. He is the former
                    President of Xicom Products and the CEO of Carolian Systems
                    and has held senior positions at Dynasty Components Inc. Mr.
                    Train holds a B.A. degree of Economics from the University
                    of Toronto with directorships and board experience in
                    various industries including Ozz Utility Management,
                    Navlyns, Blumont Capital, etc.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion className={styles.accordionCard}>
              <Card className={styles.accordionCardInterior}>
                <img src={gary} alt="Gary Kissack" />
                <p>Gary Kissack</p>
                <p>Board Member</p>
                <Card.Header className={styles.accordionButton}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className={styles.accordionToggle}
                    onClick={() => setShowBio6(!hideBio6)}
                  >
                    {hideBio6 ? "Hide Bio" : "Show Bio"}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className={styles.accordionText}>
                    Gary has extensive experience working as a lawyer and
                    advisor in various industries. He practices in a number of
                    areas of business law, including M&A, private equity,
                    venture capital, capital markets, and corporate finance. He
                    is a Partner in the Business Law and Indigenous Law Practice
                    Groups at Fogler, Rubinoff LLP in Toronto. Gary regularly
                    advises Canadian and international companies, underwriters,
                    investors and professional advisors on a range of
                    transactions including IPOs, private placements, etc. He has
                    been a Board Member of 01 Communique since 2006.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          {/* <div className={styles.accordionContainer}> */}

          {/* </div> */}
        </div>
      </div>
      <CTABanner
        tagline="Step Into The Future"
        CTA="video"
        CTADetail="how video"
      />
    </Layout>
  )
}

export default AboutUsPage
